import  React ,{useState,useEffect} from 'react'
import { ApolloClient, InMemoryCache,useSubscription,gql,useQuery, resetApolloContext,createHttpLink,} from '@apollo/client';
import UsageStats from '../UI/UsageStats';
import { enUS } from 'date-fns/locale';
import {format} from "date-fns"
import routes from '../storage_apollo_routes';

//conversions from visits to login
//conversions from logins to active user
//
const visitConversions = gql
`query VCs {
visitConversions{
  signUps{
    createdAt
    
  }
  visits
}
}
`
const sharesPerDay = gql`
query getShares{
  sharesPerDay
}`
const northStar = gql`
query NS($year:Int){
  northStar(year:$year)
}`

const getUploads = gql`
query getDailyUploads{
    UploadsPerDay{
       uploads{ 
        createdAt
      }
    }
}`
const getAnalytics = gql`
query CohortAnalysis {
   getCohortData{
        cohorts
        retention
      }
}
`
const getARPUSTATS = gql`
query ARPU($month:Int,$year:Int) {
   getARPUmonths(month:$month,year:$year){
      Months
      ARPU
      }
}
`

const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });

const Analytics = ()=>{
//calc wow growth
//leads in different stages of your funnel
//percentage of signups from referrals,wom,etc etc
//get monthly rev w/o calcing arpu
//retention rate

//NorthStar Guides Monthly Revenue
//Uploads per day
//Shares per daycd
    const [date,setDate] = useState({month:9,year:2024})
    const [data_,setData] = useState(null)
    const [options,setOptions] = useState(null)
    const [graphData,setGraphData] = useState(null)
    const [graphDataChurned,setGraphDataChurned] = useState(null)
    const [graphData_2,setGraphData_2] = useState(null)
    const [graphData_uploads,setGraphData_uploads]  = useState(null)
    const [graphData_shares,setGraphData_shares]  = useState(null)
    const [graphData_visitConversions,setVCs] = useState(null)
    const [graphDataNorthStar,setNorthStar] = useState(null)
    const [cohorts,setCohorts] = useState(null)
    const [ARPUs,setARPUs] = useState(null)
    // const [uploads,setUploads] = useState(null)

    useEffect(()=>{
        getCohortData()
       getARPUMONTHS()
       getDailyUploads()
       getDailyShares()
       getVisitConversions()
       getNorthStar()
            },[])

            const processData_northStar = (data_northStar)=>{
              //1 user  == ghc 7
              //for each month  add all OTpayments and newly registered users
              let m_s = ["January","February","March","April","May",
              "June","July","August","September","October","November","December"
              ]  
              //setdata
              let countByMonth = (n)=>{
                let monthsAndUsers = {}
                for(let j in m_s){
                  monthsAndUsers[m_s[j]] = 0
                }
                for(let i in n.users){
                  let month = new Date(n.users[i].createdAt).getMonth()
                  // let monthString = this.monthMap(month)
                  let monthString = enUS.localize.month(month)
            
             
              console.log(monthsAndUsers[monthString])
              if(monthsAndUsers[monthString] > 0){
              
                monthsAndUsers[monthString] = 7 + monthsAndUsers[monthString]
                continue
              }
              
                monthsAndUsers[monthString] = 7
              
              
                }
                for(let i in n.otpayments){
                  let month = new Date(n.otpayments[i].createdAt).getMonth()
                  // let monthString = this.monthMap(month)
                  let monthString = enUS.localize.month(month)
            
             
              console.log(monthsAndUsers[monthString])
              if(monthsAndUsers[monthString] >= 0){
              
                monthsAndUsers[monthString] = n.otpayments[i].amount + monthsAndUsers[monthString]
                continue
              }
              
                monthsAndUsers[monthString] = n.otpayments[i].amount
              
              
                }

                //do another loop and add 7* no. of active users for that month
                return monthsAndUsers
              }
              console.log(countByMonth(data_northStar))
              let data_complete = countByMonth(data_northStar)
              const options = {
                onClick: (evt, element) =>{
                  if(element.length > 0) {
                    //this.getAssociatedPosts(evt.chart.data.labels[element[0].element.$context.index],element[0].element.$context.index)
                    //console.log(element[0].element.$context,element[0].element.$context.datasetIndex,element[0].element.$context.parsed,evt.chart.data.labels[element[0].element.$context.index])
                    // you can also get dataset of your selected element
                    //console.log(data.datasets[element[0]._datasetIndex])
                  }
                },
                responsive: true,
                scales:{
                  y:{
                     ticks: {
                          // Include a dollar sign in the ticks
                          callback: function(value,index,ticks){
                              return ` GHC ${value}` ;
                          }
                      },
                      max:3000
                  },
                  x:{
                    // type:"time",
                    // // min:startDate,
                      //max: 3000,
                    // time:{
                    //   unit:"day"
                    // }
                  }
                },
            
              };
            
              //let forLabel = concatDays(newData)
              //console.log(forLabel)
               const dat = {
                labels:Object.keys(data_complete),
                datasets: [
                  {
                    label:"Monthly Revenue",
                    data: Object.keys(data_complete).map((k)=>data_complete[k]),
                    borderColor: "#000",
                    backgroundColor: "#000",
                    fill:true
                  }
                ],
              };
            
              let config = {
            data:dat,
            options:options
              }
            setNorthStar(config)
            }

const processData_2 = (data_2)=>{
const AVG_WOW  = (dat)=>{
    let stop = dat.length
    let ans = 0
    console.log(dat)

 for(let i = 0;i<stop;i++){
    let next =  parseInt(i+1)
    let current =  parseInt(i)
    //console.log(dat[next],i)

    if(dat[next]){
    let  perincrease = (dat[next]-dat[current])/(dat[current])
     ans = ans + perincrease
     console.log(ans,perincrease)
    }
 }
//  else{
//     console.log(ans,dat,dat[i] - dat[i-1],dat[i], dat[i-1],i)
//     ans = ans + (dat[i] - dat[i-1])/dat[i]   
// }
console.log(ans/stop)
return `${100 *(ans/(stop-1))}%`
    

}
let d = Object.keys(data_2.Months).map((k)=>(data_2.Months[k])*parseFloat(data_2.ARPU))
let MOM = AVG_WOW(d) 
const newDat = {
    labels:Object.keys(data_2.Months),
    datasets: [
      {
        label:`Monthly  Revenue (ARPU: GHC${(data_2.ARPU)}) ${MOM}`,
        data: d,
        borderColor: "#000",
        backgroundColor: "#000",
        // fill:true
      }
    ],
  };

  const config = {
    data :newDat,
        options:{responsive: true
        },
  }
setGraphData_2(config)
}
const processData = (data_) =>{
    if(data_){
        let c = false
        let datasets = Object.keys(data_.retention).map((k)=>{
            if(!c){
                c = Object.keys(data_.retention[k])
            }
            let stringu = k.split(" ")
            let d = stringu[2]
            let m_year = stringu[1]
           let object = {
            day:`${d}/${m_year}`,...data_.retention[k]
           }
          return object
            
           })

       console.log("hjugvjhvhtfxfhvcjhbjjkb",Object.keys(data_.cohorts))

        let parsed = Object.keys(data_.cohorts).map((key,i)=>{
          var r = Math.floor(Math.random() * 255);
          var g = Math.floor(Math.random() * 255);
          var b = Math.floor(Math.random() * 255);
          let dataset = {}
          dataset["label"] = `cohort${i}:${data_.cohorts[key].length}`
          dataset["data"] = datasets
          dataset["backgroundColor"] = `rgba(255,25,${10*i},${Math.abs(i)})`
          dataset["borderColor"] = `rgba(${r},${g},${b})`
          dataset["tension"] = 0.4
          dataset["showLine"] = true 
          dataset["spanGaps"] = true
          dataset["parsing"] = {
            yAxisKey:c[i],
            xAxis:`day`
          }
          return dataset
        }
            
            )

        let config = {
            type:"line",
            data:{datasets:parsed,
            },
            options:{
                // responsive: true,
                // maintainAspectRatio: false,
                spanGaps:true,
                parsing:{
                    xAxisKey:"day"

                },
                scales:{
                    y:{
                        //ticks:{beginAtZero:true}
                        min:0,
                        // max:100
                    },
                    
                }
            }
        }
        console.log("called",config)
        setGraphData(config)


    }
}

const daySharesFilter =(currentYear,m,dateObj,shares) =>{
  
  const  concatDays = (data)=>{
    let map = {}
    let newDataSum = []
    let newData = []
    for(let j in data){
      if(!map[data[j].createdAt]){
       map[data[j].createdAt] = true
       let date = new Date(data[j].createdAt)
       let fmt = format(date,"yyyy-MM-dd")
       newData = newData.concat([fmt])
      }

    }
    console.log(212,map)
    for (let key of Object.keys(map)){
      newDataSum.push(dayEarningTotal(data,key))
    }
    return [newData,newDataSum]
    }

   const dayEarningTotal = (data,day) =>{
        let sum = 0
        for(let i in data){
          if(data[i].createdAt == day)
         sum =  sum + 1
        }
        return sum
      }

      let data = shares
      let newData = data.filter((d)=>{
       let convert = d.createdAt
       let dataYear = new Date(convert).getFullYear()
       let month = new Date(convert).getMonth()
       // let monthString = this.monthMap(month)
       let monthString  = parseInt(dateObj.substring(5,7))
       //enUS.localize.month(array[i].month-1)
       console.log(enUS.localize.month(monthString-1),enUS.localize.month(month),"month",month,enUS.localize.month(monthString-1) == enUS.localize.month(month-1) )
      
       if(enUS.localize.month(monthString-1) == enUS.localize.month(month) && currentYear == dataYear) return true
       return false
      })


      let getParsedDate = (a,b)=>new Date(a,b,0).getDate()
   let dateObjM = dateObj.substring(5,7)
   let startDate = `${dateObj}-01`
   let endDate = `${dateObj}-${getParsedDate(currentYear,dateObjM,0)}`
   console.log(startDate,endDate)
   const options = {
    onClick: (evt, element) =>{
      if(element.length > 0) {
        //this.getAssociatedPosts(evt.chart.data.labels[element[0].element.$context.index],element[0].element.$context.index)
        //console.log(element[0].element.$context,element[0].element.$context.datasetIndex,element[0].element.$context.parsed,evt.chart.data.labels[element[0].element.$context.index])
        // you can also get dataset of your selected element
        //console.log(data.datasets[element[0]._datasetIndex])
      }
    },
    responsive: true,
    scales:{
      y:{
         ticks: {
              // Include a dollar sign in the ticks
              callback: function(value,index,ticks){
                  return `${value} shares`;
              }
          }
      },
      x:{
        type:"time",
        min:startDate,
        max:endDate,
        time:{
          unit:"day"
        }
      }
    },

  };

  let forLabel = concatDays(newData)
  console.log(forLabel)
   const dat = {
    labels:forLabel[0],
    datasets: [
      {
        label:"Month",
        data: forLabel[1],
        borderColor: "#000",
        backgroundColor: "#000",
        fill:true
      }
    ],
  };

  let config = {
data:dat,
options:options
  }

  console.log("291",config)

  setGraphData_shares(config)
}

const processData_Vcs = (data_)=>{
let visits = data_.visits
let signUps = data_.signUps
let map = {}
  //[[date,count],[date,count]] vs [{createdAt},{createdAt}]
  for(let i = 0;i < visits.length;i++){
    map[visits[i][0]] = parseInt(visits[i][1])

  }
  
  let data = []
 
  let reduced_signups = {}
  

  for(let k = 0;k < signUps.length;k++){
    let f = new Date(signUps[k].createdAt).toDateString() 
    let k_ = f.split(" ")
    let m_format = [ k_[1],k_[2],k_[3]].join(":")
   reduced_signups[m_format] = reduced_signups[m_format]?reduced_signups[m_format]+1:1
  }

  let keyMap = Object.keys(reduced_signups)

  for(let j = 0;j< keyMap.length;j++ ){
    //let format = new Date(signUps[i].createdAt).toDateString() 
    //let key = format.split(" ")
    //let m_format = [ key[1],key[2],key[3]].join(":")
   // visited[m_format] = visited[m_format]?visited[m_format]+1:1
    let value = map[keyMap[j]]?reduced_signups[keyMap[j]]/map[keyMap[j]]:0
    data = data.concat([value])
    // new Date(Date.now()).toDateString()

  }

  // let dates = signUps.map((login,idx)=>{
  //   let date = new Date(login.createdAt)
  //   let fmt = format(date,"yyyy-MM-dd")
  //   return fmt
  // })
  const options = {
    onClick: (evt, element) =>{
      if(element.length > 0) {
        //this.getAssociatedPosts(evt.chart.data.labels[element[0].element.$context.index],element[0].element.$context.index)
        //console.log(element[0].element.$context,element[0].element.$context.datasetIndex,element[0].element.$context.parsed,evt.chart.data.labels[element[0].element.$context.index])
        // you can also get dataset of your selected element
        //console.log(data.datasets[element[0]._datasetIndex])
      }
    },
    responsive: true,
    scales:{
      y:{
         ticks: {
              // Include a dollar sign in the ticks
              callback: function(value,index,ticks){
                  return `${value *100}% logins/visits`;
              }
          }
      },
      x:{
        // type:"time",
        // // min:startDate,
        // // max:endDate,
        // time:{
        //   unit:"day"
        // }
      }
    },

  };

  //let forLabel = concatDays(newData)
  //console.log(forLabel)
   const dat = {
    labels:keyMap,
    datasets: [
      {
        label:"D",
        data: data,
        borderColor: "#000",
        backgroundColor: "#000",
        fill:true
      }
    ],
  };

  let config = {
data:dat,
options:options
  }
setVCs(config)
  //console.log(keyMap,data,map,"yuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu")
}

const monthFilter =(currentYear,m,dateObj,uploads)=>{
    // console.log(e.target.value)
    // let date = new Date(e.target.value)
    // let currentYear = date.getFullYear()
    // let m = parseInt(e.target.value.substring(5,7))-1
    // this.setState({active:[true,false,false,false,false],monthState:true})
    // this.props.monthFilter(currentYear,m,e.target.value)

   const  concatDays = (data)=>{
        let map = {}
        let newDataSum = []
        let newData = []
        for(let j in data){
          if(!map[data[j].createdAt]){
           map[data[j].createdAt] = true
           let date = new Date(data[j].createdAt)
           let fmt = format(date,"yyyy-MM-dd")
           newData = newData.concat([fmt])
          }
    
        }
        console.log(212,map)
        for (let key of Object.keys(map)){
          newDataSum.push(dayEarningTotal(data,key))
        }
        return [newData,newDataSum]
        }

       const dayEarningTotal = (data,day) =>{
            let sum = 0
            for(let i in data){
              if(data[i].createdAt == day)
             sum =  sum + 1
            }
            return sum
          }
    let data = uploads
   let newData = data.filter((d)=>{
    let convert = d.createdAt
    let dataYear = new Date(convert).getFullYear()
    let month = new Date(convert).getMonth()
    // let monthString = this.monthMap(month)
    let monthString  = parseInt(dateObj.substring(5,7))
    //enUS.localize.month(array[i].month-1)
    console.log(enUS.localize.month(monthString-1),enUS.localize.month(month),"month",month,enUS.localize.month(monthString-1) == enUS.localize.month(month-1) )
   
    if(enUS.localize.month(monthString-1) == enUS.localize.month(month) && currentYear == dataYear) return true
    return false
   })

  //  let date = new Date(e.target.value)
  //  let currentYear = date.getFullYear()
  //  let m = date.getMonth()
  //  this.props.monthFilter(currentYear,m,e.target.value)
   let getParsedDate = (a,b)=>new Date(a,b,0).getDate()
   let dateObjM = dateObj.substring(5,7)
   let startDate = `${dateObj}-01`
   let endDate = `${dateObj}-${getParsedDate(currentYear,dateObjM,0)}`
   console.log(startDate,endDate)
   const options = {
    onClick: (evt, element) =>{
      if(element.length > 0) {
        //this.getAssociatedPosts(evt.chart.data.labels[element[0].element.$context.index],element[0].element.$context.index)
        //console.log(element[0].element.$context,element[0].element.$context.datasetIndex,element[0].element.$context.parsed,evt.chart.data.labels[element[0].element.$context.index])
        // you can also get dataset of your selected element
        //console.log(data.datasets[element[0]._datasetIndex])
      }
    },
    responsive: true,
    scales:{
      y:{
         ticks: {
              // Include a dollar sign in the ticks
              callback: function(value,index,ticks){
                  return `${value} uploads`;
              }
          }
      },
      x:{
        type:"time",
        min:startDate,
        max:endDate,
        time:{
          unit:"day"
        }
      }
    },

  };

  let forLabel = concatDays(newData)
  console.log(forLabel)
   const dat = {
    labels:forLabel[0],
    datasets: [
      {
        label:"Month",
        data: forLabel[1],
        borderColor: "#000",
        backgroundColor: "#000",
        fill:true
      }
    ],
  };

  let config = {
data:dat,
options:options
  }

  console.log("291",config)

  setGraphData_uploads(config)
 
 // this.setState({filteredData:dat,options:options,isYearly:false,ismonthly:true,m:m,dateObj:dateObj,monthFilterTypeData:newData})
    }
  

const processDataChurn = (data_) =>{
    console.log("177")
    const returnChurned= (obj,keys)=>{
        console.log("180")
        let empty = {}
        for(let i in keys){
            empty[keys[i]] = 100 - parseInt(obj[keys[i]])

        }
        console.log(empty,"184")
        return empty

    }
    if(data_){
        let c = false

        let datasets = Object.keys(data_.retention).map((k)=>{
            if(!c){
                c = Object.keys(data_.retention[k])
            }
           let churned = returnChurned(data_.retention[k],c)
            let stringu = k.split(" ")
            let d = stringu[2]
            let m_year = stringu[1]
           let object = {
            day:`${d}/${m_year}`,...churned
           }
          
          return object
            
           })



       let DC = Object.keys(data_.cohorts)

        let parsed = DC.map((key,i)=>{
          var r = Math.floor(Math.random() * 255);
          var g = Math.floor(Math.random() * 255);
          var b = Math.floor(Math.random() * 255);
          let dataset = {}
          dataset["label"] = `cohort${i}:${data_.cohorts[key].length}`
          dataset["data"] = datasets
          dataset["backgroundColor"] = `rgba(255,25,${10*i},${Math.abs(i)})`
          dataset["borderColor"] = `rgba(${r},${g},${b})`
          dataset["tension"] = 0.4
          dataset["showLine"] = true 
          dataset["spanGaps"] = true
          dataset["parsing"] = {
            yAxisKey:c[i],
            xAxis:`day`
          }
          return dataset
        }
            
            )

        let config = {
            type:"line",
            data:{
                datasets:parsed,
            },
            options:{
                // responsive: true,
                // maintainAspectRatio: false,
                spanGaps:true,
                parsing:{
                    xAxisKey:"day"

                },
                scales:{
                    y:{
                        //ticks:{beginAtZero:true}
                        min:0,
                        // max:100
                    },
                    
                }
            }
        }
        console.log("called",config)
        setGraphDataChurned(config)


    }
}
    const getCohortData = async ()=>{
        await client.query({
            query:getAnalytics
        }).then((result)=>{
            setData(result.data.getCohortData)
            processData(result.data.getCohortData)
            processDataChurn(result.data.getCohortData)
            
        }).catch((error)=>{
            console.log(error)
        })
    }

const getDailyShares = async ()=>{
  await client.query({
    query:sharesPerDay
}).then((results)=>{
  //date.month, date.year, toStr
  //setShares(results.data.sharesPerDay.shares)
    daySharesFilter(date.year,date.month,`${date.year}-${date.month}`,results.data.sharesPerDay.shares)
}).catch((e)=>{
    console.log(e)
})
}
    const getDailyUploads = async()=>{
await client.query({
    query:getUploads
}).then((results)=>{
  //date.month, date.year, toStr
    monthFilter(date.year,date.month,`${date.year}-${date.month}`,results.data.UploadsPerDay.uploads)
}).catch((e)=>{
    console.log(e)
})
    }

    const getNorthStar = async ()=>{
await client.query({
  query:northStar,
  variables:{
    year:2024
  }
}).then((r)=>{
  console.log(r.data.northStar)
  processData_northStar(r.data.northStar)

}).catch((e)=>{
  console.log(e)
})
    }

    const getVisitConversions = async()=>{
await client.query({
  query:visitConversions
}).then((r)=>{
  console.log(r.data.visitConversions)
  processData_Vcs (r.data.visitConversions)

}).catch((e)=>{
  console.log(e)
})
    }
const getARPUMONTHS = async()=>{
    await client.query({
        query:getARPUSTATS,
        variables:{
            month:8,
            year:2024
        }
    }).then((res)=>{
        console.log(res.data.getARPUmonths)
        setARPUs(res.data.getARPUmonths)
        processData_2(res.data.getARPUmonths)
      
    }).catch((e)=>{
        console.log(e)
    })
}

    return (<div className="container"> 
     <div style={{zIndex:"99",position:"relative"}}>
      <div><p>
        Current calculation is new Signups + Otpayments. Other calculations like 0.8 * all transactions(tips,referrals,ppvs) omitted
        </p></div>
     <div className="list2-wrapper" ng-app="app" ng-controller="MainCtrl as ctrl">
    { graphDataNorthStar &&< UsageStats config={graphDataNorthStar} />}
                                  </div>
     <div className="list2-wrapper" ng-app="app" ng-controller="MainCtrl as ctrl">
    { graphData &&< UsageStats config={graphData} />}
                                  </div>
                                  <div className="list2-wrapper" ng-app="app" ng-controller="MainCtrl as ctrl">
    { graphData_shares &&< UsageStats config={graphData_shares} />}
                                  </div>
                                  {/* <div>Removed ARPU for redundancy</div>
                                 <div className="list2-wrapper" ng-app="app" ng-controller="MainCtrl as ctrl">
    { graphData_2 &&< UsageStats config={graphData_2} />}
</div> */}
                                  <div className="list2-wrapper" ng-app="app" ng-controller="MainCtrl as ctrl">
    { graphDataChurned &&< UsageStats config={graphDataChurned} />}
                                  </div>
                                  <div className="list2-wrapper" ng-app="app" ng-controller="MainCtrl as ctrl">
                                    <input name='YEAR' value={date.year} onChange={(e)=>setDate({...date,year:e.target.value})} type="number"/>
                                    <input  name="Month" value={date.month} onChange={(e)=>setDate({...date,month:e.target.value})} type="number"/>
                                  <button onClick={()=>{getDailyUploads()}}>Change Month</button>
    { graphData_uploads &&< UsageStats config={graphData_uploads} />}
                                  </div>
                                  <div className="list2-wrapper" ng-app="app" ng-controller="MainCtrl as ctrl">
    { graphData_visitConversions &&< UsageStats config={graphData_visitConversions} />}
                                  </div>
    </div>
    </div>)
}

export default Analytics