import React, { Component, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import { ApolloClient, InMemoryCache,useSubscription,gql,useQuery} from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { Link } from 'react-router-dom'

import './Styles/Alertslist.css'
const ath = localStorage.getItem("userID")

const httpLink = new HttpLink({
  uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
});

const wsLink = new GraphQLWsLink(createClient({
  url: "ws://localhost:4000/graphql",
  connectionParams: {
    authentication: ath,
  },

}));
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);
const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
  });

  /**
   * @
   */
/**
 * 
 * @param {Alert} result
 * 
 */
const  Alertslist = React.forwardRef(({fetchMore,data,subscribeToNewComments,...result},ref) =>{
console.log("HHHHHHHHHHHHHHHHHHHH",result,data)
  const [limit,setLimit] = useState(8)
  const [offset,setOffset] = useState(0)
const [active,setActive] = useState("all")
const child = React.createRef()

const HandleScroll = async () => {
  //   const {limit,offset,user,posts} = this.state
  // if(loading) return
  // setVal(val);

  //706 == bottm of page(more or less) child.current.scrollTop >= child.current.scrollHeight /2 /child.current.clientHeight
  const bottom = child.current.scrollTop >= child.current.scrollHeight /2 /child.current.clientHeight; //child.current.clientHeight
  console.log(
    bottom,
    child.current.scrollHeight,
    child.current.scrollTop,
    child.current.clientHeight
  );
  if (bottom) {
    await fetchMore({
      variables: {
        offset: offset,
        limit: limit,
        id: parseInt(localStorage.getItem("userID")),
      },
    });
    if (data) {
      setLimit(data.getAlerts.length * 2);
      setOffset(data.getAlerts.length);
    }
  }
};

    useEffect(() => subscribeToNewComments(limit,offset), []);

   /** 
    * Returns a string indicating the viewstate of an Alert
    * @param {integer} alert.hasViewed - The viewState of the alert.
    */
    const viewState = (param) =>{
     return (param)?"-viewed":""
    }

       /** 
    * Returns a string indicating the type of an Alert
    * @param {integer} alert.type - The type an Alert.
    */
    const mapType = (param)=>{
      console.log("called alertlist subscribetoMore")
      let types = {
        message:" fa-message",
        favorited:" fa-heart",
        payment:"fa-usd",
        tip:"fa-usd",
        withrawal:" fa-check",
        comment:"fa-reply",
        referral:"fa-user-o",
        payperview:"fa-eye",
        subscription:"fa-check"
      }
      return types[param]
    }

           /** 
    * Returns a string indicating the type of an Alert
    * @param {string} alert.type - The type of an Alert.
    * @param {integer} alert.postId - The postId of an Alert.
    * @param {integer} alert.commentId - The postId of an Alert.
    */
     const getPath = (type,post,param) =>{
      if(type == "comment"){
        return `/post/${post}`
      }
      if(type == "favorited"){
        return `/post/${post}`
      }
      return ""
     }

     /** 
      * Changes the viewState of an Alert
      * @param {integer} alert.id -The id of an Alert
     */
     const toggleAlert =(id)=>{

    client
    .mutate({
      mutation: gql`
        mutation modifyAlert {
        toggleAlert(id:${id})
        }
      `,
    })
    .then((result) => {

      this.setState({comments:result.data.toggleAlert})

})
    .catch((err) => {
      console.log(err);

    });
     }
/**
 * Deletes an Alert from the database
 * @param {integer} alert.id - The id of an Alert.
 */
    const removeAlert = (id)=>{
       let fn = ()=>  subscribeToNewComments()
        client
        .mutate({
          mutation: gql`
          mutation {
            deleteAlert(id:${id})
          }
        `,
        }).then((res)=>{
       console.log(res)
       if(res.data.deleteAlert == true){
          return window.location.href = "/alerts"

       }
    
    }).catch((err)=>{
          console.log(err)
        })
   
     
    }
    const filterByType = (type)=>{
// let typeMap ={
//   "likes":true,
//   "replies":true,
//   "referral":true,
//   "tips":true,
//   "all":true
// }
setActive(type)
console.log("yooo")
    }

     return <div       style={{
      "overflow-y": "scroll",
      height: "70rem",
      "border-radius": "5px",
      paddingTop:"22px"
    }} onScroll={HandleScroll} ref={child}    className="dialogue-container">
     
<div className='container'>
<div  className="c__ example-one">
  <div className="title"></div>
  <header  className="example-one-header scroll">
    <span style={{cursor:"pointer"}} onClick={()=>filterByType("all")} className="logo">All</span>
    <nav className="vertical-align-middle">
      <span onClick={()=>filterByType("referral")}className="homepage_nav-item">referrals</span>
      <span onClick={()=>filterByType("tip")}className="homepage_nav-item">tips</span>
      <span className="homepage_nav-item" onClick={()=>filterByType("favorited")}>likes</span>
      <span onClick={()=>filterByType("comment")}className="homepage_nav-item">replies</span>
      <span onClick={()=>filterByType("subscription")}className="homepage_nav-item">subscription</span>
    </nav>
  </header>
</div>
{data && data.getAlerts.map(res =>  {
 if(active == "all") return (<div style={{borderBottom:"3px solid rgb(84 75 27 / 10%)"}} key={res.id} className="dialog-box">
 <div className={`background-blur${viewState(res.hasViewed)}`}></div>
 <div className="header">
   <div className={`background-blur${viewState(res.hasViewed)}`}></div>
   <div className="contents">
   <div  style={{fontSize: "22px"}} className="leftAlert">
    <Link onClick={()=>toggleAlert(res.id)} to={{pathname:getPath(res.type,res.postId,res.commentId),highlight_comment:res.commentId,reply:res.replyId}}> <i className={`fa ${mapType(res.type)}`}></i></Link> {res.message}
   </div>
 
   <div className="rightAlert">
   {res.createdAt}
   </div>
   </div>
 </div>
 <div class="contents main-content">
   <strong>
     {/* E */}
   </strong>
   <br/>
   {/* O */}
 </div>
 </div>)
 if(res.type == active) return (<div style={{borderBottom:"3px solid rgb(84 75 27 / 10%)"}} key={res.id} className="dialog-box">
<div className={`background-blur${viewState(res.hasViewed)}`}></div>
<div className="header">
  <div className={`background-blur${viewState(res.hasViewed)}`}></div>
  <div className="contents">
  <div  style={{fontSize: "22px"}} className="leftAlert">
   <Link onClick={()=>toggleAlert(res.id)} to={{pathname:getPath(res.type,res.postId,res.commentId),highlight_comment:res.commentId,reply:res.replyId}}> <i className={`fa ${mapType(res.type)}`}></i></Link> {res.message}
  </div>

  <div className="rightAlert">
  {res.createdAt}
  </div>
  </div>
</div>
<div class="contents main-content">
  <strong>
    {/* E */}
  </strong>
  <br/>
  {/* O */}
</div>
</div>)})}
</div>
</div>
  
})

export default Alertslist;
//<div
// className="dropdown"><div className='conta'>{result.data && result.data.getAlerts.map(res => (<div> <div  className="result"><div className="fav">
// {/* <i className="fa fa-trash-o"onClick={()=>removeAlert(res.id)} aria-hidden="true" ></i> */}
// </div><p>{res.message}</p></div></div>))}    
// </div></div>
//     </div>