// let routes = {
// apollo:"http://localhost:4000/graphql",
// uhsoka:"http://localhost:3000/",
// storage_1:"/storage/",
// storage_2:"./storage/",
// minio:"https://images.uhsoka.com/public/",
// apollo_ws:"ws://localhost:4000/graphql"
// }
//https://wild-candles-shine.loca.lt -9000
//https://wide-sides-begin.loca.lt -4000
//https://uhsoka1.netlify.app/

let routes = {
    apollo:"https://apollo.uhsoka.com/graphql",
    uhsoka:"https://uhsoka.com/",
    storage_1:"https://images.uhsoka.com/uhsoka/public/",
    storage_2:"https://images.uhsoka.com/uhsoka/public/",
    minio:"http://localhost:43763/uhsoka/public/",
    apollo_ws:"wss://apollo.uhsoka.com/graphql"
    }
//https://rnfwt-154-161-166-199.a.free.pinggy.link

//   Active:        0     Total:         6 connect_to localhost port 4000: failed.
//https://rneqk-154-161-166-199.a.free.pinggy.link https://rnapp-154-161-30-70.a.free.pinggy.link/uhsoka/public/$2b$10$ZIIjgY2JFDXT7CxyKTLHYuKnsxaTbp5UF.aKWsmjK6lxhuVeien0a.mp4

    // let routes = {
    //     apollo:"https://rnywz-154-161-188-75.a.free.pinggy.link/graphql",
    //     uhsoka:"https://uhsoka1.netlify.app/",
    //     storage_1:"https://rnebp-154-161-188-75.a.free.pinggy.link/uhsoka/public/",
    //     storage_2:"https://rnebp-154-161-188-75.a.free.pinggy.link/uhsoka/public/",
    //     minio:"https://images.uhsoka.com/public/",
    //     apollo_ws:"wss://rnywz-154-161-188-75.a.free.pinggy.link/graphql"
    //     }
export default routes