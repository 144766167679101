import { PaystackButton } from 'react-paystack'

function RealPay(props) {
let amount = props.price
    const handlePaystackSuccessAction = (reference) => {
       //handle deposit logic
       if(reference.status = "success"){
        console.log(props)
        props.deposit("paystack")
       }
        console.log(reference);
        //callback({channel:reference.channel})
      };
  
     
      const handlePaystackCloseAction = () => {
       
        console.log('closed',props.price,props)
      }
    let config = {
        reference: (new Date()).getTime().toString(),
        email: "user@example.com",
        amount: parseInt(amount) *100, 
        publicKey: 'pk_live_fd8564ae22c6fa1d5c7aeef22d67997d81c818af',
        channels:["card","mobile_money"],
        currency:"GHS"
      };


      let componentProps = {
        ...config,
        className:props.className,
        text: 'try other options',
        onSuccess: (reference) => handlePaystackSuccessAction(reference),
        onClose: handlePaystackCloseAction,
    };
    console.log("realpay active")
    return (<div>
        <PaystackButton {...componentProps}/>
    </div>  );
}

export default RealPay;