import { methodOf } from 'lodash';
import routes from '../storage_apollo_routes';
import React ,{Component,useEffect,useState} from 'react';
import Popup3 from './Popup3';
import ReactDOM from 'react-dom';
import Loader from './Loader';
import RealPay from '../Controllers/RealPay';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
import { CopyToClipboard } from 'react-copy-to-clipboard';
const authLink_1 = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "X-Pinggy-No-Screen": "true",
    },
  };
});
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink_1.concat(link),
});

class FirstPay extends Component {
  constructor(props){
    super(props);
  this.state={
    amount:'',
    date:'',
    open:this.props.isOpen,
    contaact:null,
    loaderState:false,
    paymentOption:null,
  
  }
    this.numberchosen =this.numberchosen.bind(this)
    this.datechosen =this.datechosen.bind(this)

  }
//choosepayment option :mtn momo , card

 firstPay = ()=>{
    const {contact,message,price} = this.state
    let msg = (!message)?"":message
    let pattern = /^(([2][3][3])|[0])?([2]|[5])([4]|[5])\d{7}$/.test(contact)
    if(pattern){
      this.setState({loaderState:true})
      client
    .mutate({
      mutation: gql`
        mutation FirstPay {
         firstPay(contact:"${contact}")
        }
      `,
    }).then((result)=>{
      
      if(result.data.firstPay.includes("SUCCESSFUL")){
        this.setState({completed:true,loaderState:false})
        let iii = result.data.firstPay.split(" ")
        console.log(iii[2],"booooom")
        this.props.onComplete()
        this.createError("Payment Successful")
        //
         }
         else{
          
          // this.props.updateProfileParams(amount)
        
          this.createError(result.data.firstPay)
          this.setState({loaderState:false})
         }
  
    }).catch((err)=>{
      this.createError("Internal Server Error")
      this.setState({loaderState:false})
    })
    }
   
  }

  createError = (message)=>{
    this.setState({errorMsg:message,iMO:true})
    console.log("message")
  }


  closeModal() {
    this.setState({iMO:false})
    
    //this.update();
  }


// close = ()=>{
//   this.setState({amount:"",date:"",open:!this.state.open})
// }

  numberchosen = (event)=>{
    this.setState({amount:event.target.value});
    
}

contactChosen = (event)=>{
    this.setState({contact:event.target.value});
    
}

datechosen = (event)=>{
  this.setState({date:event.target.value});
  
}
 
onChangeValue =(e)=>{
  console.log(e.target.value);
  this.setState({paymentOption:e.target.value})

}

    close(e) {
      e.preventDefault()
  
      if (this.props.onClose) {
        this.props.onClose()
      }
    }
   
    
    render() {
     
      if (!this.props.isOpen )
        return null
  
      return (
       
        <div className="boxxxx">
         <Popup3 isOpen={this.state.iMO} error={this.state.errorMsg} onClose={() => this.closeModal()}></Popup3>
        <div style={{backgroundColor:"#86a79f",zIndex:9999,width:"400px"}} className="cenceph_pure_black">
       {/* {!this.props.OTP && <p><h1 style={{fontSize:"18px",fontWeight:"2000"}}>{this.props.FirstPay}</h1></p>} */}
        
        { <div> <p ><h1 style={{fontSize:"23px",fontWeight:"2000"}}>Getting Started</h1> 
       <br/> your files are uploaded and your links are ready to be shared. Pay <br/> <span className="tooltip tilt-shake" style={{color:"#4a7ea3",position:"static"}}><p  aria-hidden="true" className='tooltiptext'>dial *170# <br/>and check your approvals(Only MTN currently supported) </p>GHC 7</span> <br/> to proceed with registration <br/>  </p></div>}
        {/* <i onClick={(e)=> this.close(e)} className="fa fa-times" aria-hidden="true"></i> */}
      <div className="wrapp">
          {/* change to create subscription bundle */}
          <div  onChange={this.onChangeValue} className="payment_option">
            {/*<div>
            <input type="radio" value="momo" name="type" /> MoMo
      </div>*/}
          <div>
          <input type="radio" value="card" name="type" /> Select Options
          </div>
        

          </div>
           {this.state.paymentOption == "card" && <RealPay price={7} deposit={this.props.onComplete} className={"wpc-btn wpc-btn-default-rounded"}/>}
         {this.state.paymentOption == "momo" && <input onChange={(e)=>this.contactChosen(e)} placeholder="eg. 233XXXXXXXXX"  type="text" />}
          <Loader isOpen={this.state.loaderState}/>
          {this.state.paymentOption == "momo" && !this.state.completed && <div className="cont"><button className="wpc-btn wpc-btn-default-rounded" onClick={()=>this.firstPay()}>pay</button></div>}
      {/* <input onChange={this.numberchosen}  value={this.state.amount} type="number" min="1" max="700" />
      <input onChange={this.datechosen}  value={this.state.date} type="number" min="1" max="100" /> */}
      
  
  
  
  
  
    
  </div>
  
  
      
      
      
  
  
  
  
  
 
  
  
  
  </div>
  
  
  
  
  
  
  </div> //<div className="bg" onClick={e => this.close(e)}/>
    
      )
    }
  
    
  }
  export default FirstPay;