import React ,{useState,useEffect} from "react"
import { Link } from "react-router-dom";
import Upload4 from "./UI/Upload4"
import routes from "./storage_apollo_routes";
import { setContext } from "@apollo/client/link/context";
import FirstPay from "./UI/FirstPay";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    
    return {
      headers: {
        "Apollo-Require-Preflight": "true",
        "X-Pinggy-No-Screen":"true"
      },
    };
  });
  const link = createHttpLink({
    uri:routes.apollo,
    
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link:authLink.concat(link),
  });

  const visit = gql`
  mutation{
    pageVisit
   
    
  }
  
  `


const Landing = () =>{
  const [uploaded,setUploaded] = useState(false)
  const [firstPayHandlerStatusComplete,setStatusComplete] = useState(false)
  const [errorMsg,setErrorMsg] = useState("")
  const [iMO,setiMO] = useState(true)
useEffect(()=>{
 visitCount()
},[])

const closeModal=()=>{
  this.setState({iMO:false,errorMsg:""})
}
const visitCount = async ()=>{
await client.mutate({
    mutation:visit
}).then((r)=>{
    console.log(r)
}).catch((e)=>{
    console.log(e)
})
}
return <div className="wrapperRegister"> 
{<FirstPay onComplete={()=>{
  setStatusComplete(true)
  setUploaded(false)
  }} isOpen={uploaded} FirstPay={errorMsg} onClose={() => closeModal()}/>}
    <Upload4 firstPayHandlerStatusComplete={firstPayHandlerStatusComplete} setUploaded={setUploaded}/>
    <div className="container">
      <p style={{fontSize: "16px", fontWeight: "600"}}className="tooltiptext">Or ...</p>
      <Link to={{pathname:"/login"}}><button style={{fontSize: "19px", fontWeight: "600"}}className='wpc-btn wpc-btn-default-rounded tilt-shake'>Login</button></Link>
      <h2 style={{fontSize: "21px", fontWeight: "600"}}> 
        What's Uhsoka? <br/>
        <span style={{color:"#ffff",backgroundColor:"blue",fontSize: "20px", fontWeight: "600"}}>Got a video or photo you want to sell? Post it here,share the link, see who's bought and how many are interested...</span>
        <br/><a href="https://chat.whatsapp.com/FVQAt3HP91v2Z9Bh1utzR8"><span  style={{fontSize:"12px"}}> i'd really like to hear from you</span></a>
      </h2>
      </div> 
</div>
}


// style={{display: "inline-flex",
// alignItems: "center",
// position:"absolute",
// top:"0",
// left:"0",
// bottom:"0",
// right:"0",
// margin: "auto",
// //overflowX: "hidden",
// maxWidth:"900px",
// // display:"flex",
// // minHeight: "100vh",
// justifyContent: "center"}}>
//     <div className="wrapperRegister">
//     <div class="card_landing-wrapper">
// <div class="card_landing">
//   <div style={{fontSize:"2rem"}} class="card_landing-inner">
//   <div style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
//       UPLOAD
//     </div>

//   </div>
// </div>
// <div class="card_landing">
//   <div style={{fontSize:"2rem"}} class="card_landing-inner">
//   <div style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
//       SHARE
//     </div>
//   </div>
// </div>
// <div class="card_landing">
//   <div style={{fontSize:"2rem"}}class="card_landing-inner">
//     <div style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
//       PROFIT
//     </div>
//   </div>
// </div>
// {/* <div class="card_landing">
//   <div class="card_landing-inner"></div>
// </div> */}
// </div>
//     </div>

export default Landing