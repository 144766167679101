//graphql Subscriptions
import React, { Component, useEffect, useRef, useState } from 'react'
import { ApolloClient, InMemoryCache,useSubscription,gql,useQuery} from '@apollo/client';
import routes from '../storage_apollo_routes';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import Alertslist from '../UI/Alertslist';
import SkeletonAlerts from '../UI/SkeletonAlerts';
import { offsetLimitPagination } from "@apollo/client/utilities";
// const client = new ApolloClient({
//   cache: new InMemoryCache({
//     typePolicies: {
//       Query: {
//         fields: {
//           getPosts_: offsetLimitPagination(),
//           //  keyArgs:false,
//           //  merge(existing=[],incoming){
//           //    return [...existing,...incoming]
//           //  },
//         },
//       },
//     },
//   }),
//   link,
// });

const ath = localStorage.getItem("userID")

const httpLink = new HttpLink({
  uri: routes.apollo,
});

const wsLink = new GraphQLWsLink(createClient({
  url: routes.apollo_ws,
  connectionParams: {
    authentication: localStorage.getItem("userID"),
  },

}));
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const ALERT_QUERY = gql`
  query Alertlist($id:Int!,$limit:Int,$offset:Int){
    getAlerts(id:$id,limit:$limit,offset:$offset){
      type
      message
      id
      createdAt
      replyId
      postId
      commentId
      hasViewed
    }
  }
`;

const ALERTS = gql`
  subscription Alerted($toUser:Int!) {
    alertAdded(toUser:$toUser){
      type
      id
      message
     
    }
  }
`;

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
        typePolicies: {
      Query: {
        fields: {
          getAlerts: offsetLimitPagination(),
          //  keyArgs:false,
          //  merge(existing=[],incoming){
          //    return [...existing,...incoming]
          //  },
        },
      },
    },
  })
});

const Alertswithpagination = (props)=>{

  console.log(ath,localStorage.getItem("userID"))
  const child = React.createRef();

// const []
const [alerts,setAlerts] = useState([])


const sub_to_more = (limit,offset)=>{
    
      subscribeToMore({
        document: ALERTS,
        // fetchPolicy:"",
        // nextFetchPolicy:"",
        variables: {  toUser:parseInt(ath),limit,offset },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newFeedItem = subscriptionData.data.alertAdded;
          // console.log("ABCDEFGHIJKLMNOP",prev)

          return Object.assign({}, prev, {
            getAlerts:  [newFeedItem, ...prev.getAlerts]
          
          });
        }
      })
}
// const { data, loading } = useSubscription(
//   ALERTS,{ variables: { toUser:parseInt(ath) },client:client });

/**
 * @typedef Alert
 * @prop {string} createAt
 * @prop {integer} type
 * @prop {integer} id
 *@prop {string} message
 */
/**
 * Gets all relevant user data
 */
  const me = ()=>{
    client
    .query({
      query: gql`
        query myQuery {
          me {
            name
            id
          }
        }
      `,
    }).then((result)=>{console.log(result)}).catch((err)=>{
      console.log(err)
    })
  }

    const {subscribeToMore,fetchMore,data,error,loading,...result} = useQuery(
      ALERT_QUERY,
      { variables: {id:parseInt(ath),limit:8,offset:0} ,client:client}
    );
    return (<div  ref={child}>
    {loading || error  && <SkeletonAlerts/>}
    { <Alertslist ref={child} {...result}
    {...result}
    fetchMore={fetchMore}
    data={data}
    subscribeToNewComments={
        (limit,offset)=>
    
            subscribeToMore({
              document: ALERTS,
              // fetchPolicy:"",
              // nextFetchPolicy:"",
              variables: {  toUser:parseInt(ath),limit,offset },
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const newFeedItem = subscriptionData.data.alertAdded;
                // console.log("ABCDEFGHIJKLMNOP",prev)
      
                return Object.assign({}, prev, {
                  getAlerts:  [newFeedItem, ...prev.getAlerts]
                
                });
              }
            })
      
    }
    />}</div>);
  

// useEffect(()=>{

//   if(data){
//     console.log(data)
//     props.append(data)
//     //setAlerts(alerts.concat([data]))
    
//   }
//   console.log("alerts",data)

// },[])



// const alert =()=> {

  

  // setAlerts(prevdata =>[...prevdata,data])


  
// }

}
export default Alertswithpagination